import { withRouter } from 'core/withRouter';
import { AdminDTO, AdminSearchDTO } from 'models/admin';
import React, { useEffect, useRef, useState } from 'react';
import adminService from 'services/admin.service';
import pageUtil from "utils/Page.util";
import AdminListV from "./Admin.list.v";

class Params {
    error: string = null;
    isLoaded: boolean = false;
    items: Array<AdminDTO>;
    total: number = 0;

    search = new AdminSearchDTO();

};

function AdminList(props: any) {

    const [params, setParams] = useState(new Params());
    const [search, setSearch] = useState(false);
    // const stateRef = useRef(new Params());
    const mounted = useRef(false);

    /****************************************************************************************** */
    /************************************** STATE ********************************************* */
    /****************************************************************************************** */

    // onUpdated
    // 검색조건이 변경된 경우
    useEffect(() => {
        if (!mounted.current || !search) {
            return;
        }

        if (search) {
            console.log("filter changed");
            setSearch(false);
            getData();
        }

    }, [search]);

    // 상태 유지
    // useEffect(() => {
    //     stateRef.current = params as any;
    // }, [params]);

    // onMount
    useEffect(() => {

        console.log("onMount");
        console.log("LOAD FROM API");
        mounted.current = true;

        console.log("mounted");
        getData();

    }, []);


    /************************************************************************************************************************ */
    /***************************************************** BASIC ************************************************************ */
    /************************************************************************************************************************ */


    function pageChanged(page: number): void {
        let search = pageUtil.deepCopy(params.search);
        search.page = page;
        setParams((params) => ({
            ...params,
            search: search
        }));

        setSearch(true);
    }

    async function onChangeRowUnit(e: any) {

        console.log("e.target.value", e.target.value);

        const search = pageUtil.deepCopy(params.search);

        search.rows = e.target.value;
        search.page = 1;

        setParams((prev) => ({
            ...prev,
            search: search
        }));

        setSearch(true);

    }

    async function getData(): Promise<any> {

        console.log("params.search", params.search);

        await adminService.getList(params.search).then(p => {

            console.log(p);

            const items = p.content.map((item) => {
                let roles = item.roles.map(p => p);
                return { ...item, roles: roles };
            })

            setParams((params) => ({
                ...params,
                error: null,
                isLoaded: true,
                items: items,
                total: p.totalElements,
            }));

        }).catch(e => {

            setParams((params) => ({
                ...params,
                isLoaded: true,
                error: e
            }));

            pageUtil.handleApiError(e);

        });

    }



    const args = {
        params: params,
        pageChanged: pageChanged,
        onChangeRowUnit: onChangeRowUnit
    }

    return (
        <AdminListV {...args} />
    );



}

export default withRouter(AdminList, [adminService]);