import { loadTree } from "core/MenuTreeHelper.js";
import { withRouter } from 'core/withRouter';
import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import authService from 'services/auth.service';
import pageStateService from 'core/PageState.service';

class Params {
  path: string = "";

}
function SideBar(props: any) {

  const navigate = useNavigate();
  const location = useLocation();
  const [params, setParams] = useState(new Params());

  // onMount 
  useEffect(() => {

    console.log("SIDEBAR onMount");

    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get("r");

    console.log("SIDEBAR redirect", redirect);

    if (!authService.getToken()) {

      if (redirect && redirect.startsWith("/find")) {
        navigate(redirect, { replace: true });
        return;
      }

      navigate("/login?r=" + location.pathname);
      return;
    }

    console.log("location.pathname", location.pathname);

    setParams(() => ({
      ...params,
      path: location.pathname
    }));

    loadTree();

    if (authService.getToken()) {

      if (redirect) {

        setTimeout(() => {
          navigate(redirect, { replace: true });
        }, 300)

      }
    }

  }, []);

  useEffect(() => {

    setParams(() => ({
      ...params,
      path: location.pathname
    }));

  }, [location]);

  async function goPage(path: string) {

    await pageStateService.clearPageState();

    navigate(path);

  }

  return (

    <aside className="main-sidebar sidebar-light-primary elevation-4">

      <a onClick={() => goPage('/')} className="brand-link">
        <span className="brand-text">DASHBOARD</span>
        <span className="brand-logo"></span>
      </a>

      <div className="sidebar">

        <nav className="mt-3">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="true">

            {(
              <li className={`nav-item ` + (params.path == "/" ? 'menu-is-opening menu-open' : '')}>
                <a className="nav-link" onClick={() => goPage('/')}>
                  <i className="nav-icon" style={{ backgroundImage: "url(/assets/admin/img/common/home.png)" }}></i>
                  <p>
                    HOME
                  </p>
                </a>
              </li>
            )}

            {(
              <li className={`nav-item ` + (params.path == "/admin" ? 'menu-is-opening menu-open' : '')}>
                <a className="nav-link" onClick={() => goPage('/admin')}>
                  <i className="nav-icon fas fa-users" ></i>
                  <p>
                    ADMIN
                  </p>
                </a>
              </li>
            )}

            {(
              <li className={`nav-item ` + (params.path == "/main" ? 'menu-is-opening menu-open' : '')}>
                <a className="nav-link" onClick={() => goPage('/main')}>
                  <i className="nav-icon fas fa-sharp-duotone fa-solid fa-bars"></i>
                  <p>
                    MAIN
                  </p>
                </a>
              </li>
            )}

            {(
              <li className={`nav-item ` + (params.path == "/faq" ? 'menu-is-opening menu-open' : '')}>
                <a className="nav-link" onClick={() => goPage('/faq')}>
                  <i className="nav-icon fas fa-sharp-duotone fa-solid fa-bars"></i>
                  <p>
                    FAQ
                  </p>
                </a>
              </li>
            )}

            {(
              <li className={`nav-item ` + (params.path == "/board" ? 'menu-is-opening menu-open' : '')}>
                <a className="nav-link" onClick={() => goPage('/board')}>
                  <i className="nav-icon fas fa-sharp-duotone fa-solid fa-bars"></i>
                  <p>
                    BOARD
                  </p>
                </a>
              </li>
            )}

          </ul>
        </nav>

      </div>

    </aside>

  );


}

export default withRouter(SideBar);
declare var $: any;
declare var Treeview: any;
