import React from 'react';
import Editor from '../_common/editor/editor';
import DatePicker from 'react-datepicker';
import ko from 'date-fns/locale/ko'; // 한국어 지역 설정

const BoardUpdateV = ({ t, params, setParams, handleChange, onFileChange, isUpdate, submit, remove }) => (
    <>

        <div className="content-header">
            <h1>
                <font>게시글</font>
            </h1>
        </div>
        <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">{isUpdate ? "수정" : "등록"}</h3>

                    <div className="card-tools">
                    </div>

                </div>
                {params.isLoaded && params.error == null && (
                    <>
                        <div className="g_update p-0 mt_30">
                            <table className="g_table table_type1">
                                <colgroup>
                                    <col width="220" />
                                    <col />
                                </colgroup>
                                <tbody>

                                    <tr>
                                        <th><label>제목</label></th>
                                        <td><div className="inner"><input className="form-control input-sm" type="text" onChange={e => handleChange(e, "item.title", setParams, params)} value={params.item.title} /></div></td>
                                    </tr>

                                    <tr>
                                        <th><label>타입</label></th>
                                        <td>
                                            <div className="inner">
                                                <span className="g_select">
                                                    <select className="form-control" onChange={e => handleChange(e, "item.type", setParams, params)} value={params.item.type}>
                                                        <option value="normal" >일반</option>
                                                        <option value="notice" >공지</option>
                                                    </select>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th><label>썸네일 </label></th>
                                        <td>
                                            <div className="inner">
                                                <input type="file" accept="image/*" onChange={onFileChange} />
                                                {params.item?.thumb?._preview && (
                                                    <>
                                                        <div className="attachment-block clearfix">
                                                            <img className="img inner" style={{ height: "150px", width: "150px" }} src={params.item.thumb._preview} />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th><label>노출여부</label></th>
                                        <td>
                                            <div className="inner">
                                                <span className="g_select">
                                                    <select className="form-control" onChange={e => handleChange(e, "item.displayYN", setParams, params)} value={params.item.displayYN}>
                                                        <option value="Y" >노출</option>
                                                        <option value="N" >미노출</option>
                                                    </select>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th><label>상시노출여부</label></th>
                                        <td>
                                            <div className="inner">
                                                <span className="g_select">
                                                    <select className="form-control" onChange={e => handleChange(e, "item.alwaysVisibleYN", setParams, params)} value={params.item.alwaysVisibleYN}>
                                                        <option value="Y" >상시노출</option>
                                                        <option value="N" >노출일 지정</option>
                                                    </select>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>

                                    {
                                        params.item && params.item.alwaysVisibleYN == "N" && (
                                            <>
                                                <tr>
                                                    <th><label>노출 시작일 </label></th>
                                                    <td>
                                                        <div className="inner">
                                                            <DatePicker
                                                                className="datetimepicker-input col-12"
                                                                // dateFormat="yyyy.MM.dd" // 날짜 형태
                                                                shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                                                placeholderText="YYYY.MM.DD hh:mm:ss"
                                                                locale={ko} // 한국어로 지역 설정
                                                                onChange={(date) => handleChange(date, "item.displayBeginDt", setParams, params)}
                                                                selected={new Date(params.item.displayBeginDt)}
                                                                showTimeSelect
                                                                dateFormat="yyyy/MM/dd h:mm aa"
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}    // 시간 간격 (15분)
                                                            />
                                                            {/* <input placeholder='YYYYMMDDHHmm' className="form-control input-sm" type="text" onChange={e => handleChange(e, "item.displayBeginDt", setParams, params)} value={params.item.displayBeginDt} /> */}
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th><label>노출 종료일 </label></th>
                                                    <td>
                                                        <div className="inner">
                                                            <DatePicker
                                                                className="datetimepicker-input col-12"
                                                                // dateFormat="yyyy.MM.dd" // 날짜 형태
                                                                shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                                                placeholderText="YYYY.MM.DD hh:mm:ss"
                                                                locale={ko} // 한국어로 지역 설정
                                                                onChange={(date) => handleChange(date, "item.displayEndDt", setParams, params)}
                                                                minDate={new Date(params.item.displayBeginDt)}   // 시작일 이후로만 선택 가능
                                                                selected={new Date(params.item.displayEndDt)}
                                                                showTimeSelect
                                                                dateFormat="yyyy/MM/dd h:mm aa"
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}    // 시간 간격 (15분)
                                                            />
                                                            {/* <input placeholder='YYYYMMDDHHmm' className="form-control input-sm" type="text" onChange={e => handleChange(e, "item.displayEndDt", setParams, params)} value={params.item.displayEndDt} /> */}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    }



                                    <tr>

                                        <th><label>내용</label></th>
                                        <td>
                                            <div>
                                                <Editor
                                                    // handleChange={handleChange}
                                                    value={params.item.content}
                                                ></Editor>
                                            </div>
                                        </td>

                                    </tr>

                                    {/* <tr>
                                        <th><label>role</label><br></br></th>
                                        <td>
                                            <div className="inner">
                                                {params.roles.map((item) => (
                                                    <div className="form-check checkbox-lg form-check-inline" key={item.key}>
                                                        <input className="form-check-input" type="checkbox" onChange={handleRoleChange} checked={item._checked} name={'role_' + item.key} id={'role_' + item.key} value={item.key} disabled={params.admin.roles?.includes('master')} />
                                                        <label className="form-check-label" htmlFor={'role_' + item.key}>{item.name}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </td>
                                    </tr> */}

                                </tbody>
                            </table>
                        </div>

                        <div className="card-footer">
                            <a className="btn btn-success float-left" onClick={() => { history.back() }}><i className='fas fa-long-arrow-left mr_10'></i>Back</a>

                            <button type="button" onClick={submit} className="btn btn-primary float-right">Save</button>
                            {isUpdate && (
                                <button type="button" onClick={remove} className="btn btn-danger float-right mr_10">Remove</button>
                            )}
                        </div>
                    </>
                )
                }

            </div>

        </div>

    </>
);

export default BoardUpdateV;