import http from 'core/http.service';
import BaseService from "services/BaseService";
import { ScheduleDTO } from 'models/schedule';

class ScheduleService extends BaseService {

    public get(startYmd: string, endYmd: string): Promise<any> {
        return super.request(http.get("/schedule", { startYmd: startYmd, endYmd: endYmd }));
    }

    public save(dto: ScheduleDTO): Promise<any> {
        return super.request(http.post("/schedule", dto));
    }

    public delete(id: number): Promise<any> {
        return super.request(http.delete("/schedule/" + id));
    }

}

let scheduleService;
export default scheduleService = new ScheduleService();