import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { dateFormat } from 'utils/Date.util';
import { getRowUnits } from "utils/util";

const AdminListV = ({ params, pageChanged, onChangeRowUnit }) => (

    <>
        <div className="content-header">
            <h1>
                <span>ADMIN</span>
            </h1>
        </div>
        <div className="container-fluid">
            <div className="card">
                <div className="card-header p-0 mt_20">
                    <div className="card-header-left">

                        <div className="g_table_sort">
                            <span className="g_select">
                                <select className="form-control" onChange={onChangeRowUnit} value={params.search.rows}>
                                    {getRowUnits().map((item, index) => (
                                        <option key={index} value={item} >{item}</option>
                                    ))}
                                </select>
                            </span>
                            / {params.total.toLocaleString()} rows
                        </div>
                    </div>

                    <div className="card-header-right">
                        <NavLink to="add"><button className="btn btn-primary" type="button">register</button></NavLink>
                    </div>
                </div>
                <div className="card-body table-responsive p-0">
                    <table className="table table-hover text-nowrap">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th className="tal">name</th>
                                <th className="tal">role</th>
                                <th className="tal">username</th>
                                <th className="tal">phone</th>
                                <th className="tal">email</th>
                                <th>regdate</th>
                            </tr>
                        </thead>
                        <tbody>
                            {params.isLoaded && params.error == null && (
                                <>
                                    {params.items.map((item, index) => (
                                        <tr key={item.username}>
                                            <td>{params.search.rows * params.search.page - (params.search.rows - (index + 1))}</td>
                                            <td className="tal">{item.name} </td>
                                            <td className="tal">{item.roles}</td>
                                            <td className="tal"><NavLink to={'update'} state={{ username: item.username, isUpdate: true }} >{item.username}</NavLink></td>
                                            <td className="tal">{item.phone} </td>
                                            <td className="tal">{item.email} </td>
                                            <td>{dateFormat(new Date(item.regdate))} </td>
                                        </tr>
                                    ))}
                                </>
                            )}
                        </tbody>
                    </table>
                </div>


                {
                    params.isLoaded && params.error == null && (
                        <PaginationControl
                            page={params.search.page}
                            total={params.total}
                            limit={params.search.rows}
                            changePage={(page) => {
                                pageChanged(page);
                                console.log(page);
                            }}
                            ellipsis={1}
                        />
                    )

                }

            </div>

        </div>
    </>
);

export default AdminListV;