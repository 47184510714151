import http from 'core/http.service';
import BaseService from "services/BaseService";
import { AdminSearchDTO, AdminDTO } from "models/admin"
import { Page } from 'models/page';

class AdminService extends BaseService {

    public getList(search: AdminSearchDTO): Promise<Page<AdminDTO>> {
        return super.request(http.get("/admin", search));
    }

    public get(username: string): Promise<AdminDTO> {
        return super.request(http.get("/admin/" + username));
    }

    public save(dto: AdminDTO, updateYN: string = "N"): Promise<void> {
        return super.request(http.post("/admin/" + updateYN, dto));
    }

    public remove(username: string): Promise<void> {
        return super.request(http.delete("/admin/" + username));
    }

}

let adminService;
export default adminService = new AdminService();