import React from 'react';
import Modal from "components/_common/modal/Modal"
import BigCalendar from "components/_common/BigCalendar"

const DashboardV = ({ t, params, mounted, handleChange, goPage }) => (

    <>
        {mounted && (
            <>
                {/* <BigCalendar></BigCalendar> */}
            </>
        )}

    </>

);

export default DashboardV;