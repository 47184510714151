import { Search } from "./search";

export class AdminSearchDTO extends Search {

}

export class AdminDTO {

    username: string;
    
    password: string;

    name: string;
    
    email: string;
    
    phone: string;

    adminId: string;

    regdate: Date;

    updatedId: string;

    active: boolean;

    roles: Array<String>

}

export class RoleDTO {

    role: String = "";

    name: String = "";

    master: string = "";

    _checked: boolean = false;

    public static roles(): Array<any> {

        return [
            { key: "admin", name: "Admin", _checked: false },
            { key: "master", name: "Master Admin", _checked: false },
            { key: "super", name: "Super Admin", _checked: false }
        ];

    }

}