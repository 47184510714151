import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { dateFormat, stringToDate } from 'utils/Date.util';
import DatePicker from 'react-datepicker';
import ko from 'date-fns/locale/ko'; // 한국어 지역 설정
import Loading from 'components/_common/Loading';

const ModalV = ({
    params,
    setParams,
    handleChange,
    closeModal,
    deleteSchedule,
    saveSchedule,
}) => (
    <>
        {params.isLoaded && params.error == null ? (
            <>
                <Modal show={true} onHide={() => closeModal()} backdrop="static" dialogClassName={'schedule-list'}>
                    <Modal.Header>
                        <Modal.Title>일정추가</Modal.Title>
                        <Button variant="close" onClick={() => closeModal()} aria-label="Close"></Button>
                    </Modal.Header>
                    <Modal.Body style={{ height: "450px" }}>
                        <div className="input-group date col-12">

                            <label htmlFor="">시작</label>
                            <DatePicker
                                className="datetimepicker-input col-12"
                                // dateFormat="yyyy.MM.dd" // 날짜 형태
                                shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                placeholderText="YYYY.MM.DD hh:mm:ss"
                                locale={ko} // 한국어로 지역 설정
                                onChange={(date) => handleChange(date, "dto.startDt", setParams, params)}
                                selected={params.dto.startDt}
                                showTimeSelect
                                dateFormat="yyyy/MM/dd h:mm aa"
                                timeFormat="HH:mm"
                                timeIntervals={15}    // 시간 간격 (15분)
                            />

                            <label htmlFor="">종료</label>
                            <DatePicker
                                className="datetimepicker-input col-12"
                                // dateFormat="yyyy.MM.dd" // 날짜 형태
                                shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                placeholderText="YYYY.MM.DD hh:mm:ss"
                                locale={ko} // 한국어로 지역 설정
                                onChange={(date) => handleChange(date, "dto.endDt", setParams, params)}
                                minDate={params.dto.startDt}   // 시작일 이후로만 선택 가능
                                selected={params.dto.endDt}
                                showTimeSelect
                                dateFormat="yyyy/MM/dd h:mm aa"
                                timeFormat="HH:mm"
                                timeIntervals={15}    // 시간 간격 (15분)
                            />

                        </div>

                        <div className="input-group col-12">
                            <label htmlFor="">제목</label>
                            <div className='col-12'>
                                <input className="form-control input col-12" id="title" name='title' type="text" value={params.dto.title} placeholder='제목을 입력해주세요.' onChange={e => handleChange(e, "dto.title", setParams, params)} />
                            </div>
                        </div>

                        <div className="input-group col-12">
                            <label htmlFor="">메모</label>
                            <div className='col-12'>
                                <textarea
                                    className="form-control"
                                    rows={5}
                                    placeholder="메모를 작성하세요."
                                    name="memo"
                                    value={params.dto.memo}
                                    maxLength={1500}
                                    onChange={(e) => handleChange(e, 'dto.memo', setParams, params)}
                                ></textarea>
                                {/* <input className="form-control input" id="memo" name='memo' type="text" value={params.dto.memo} placeholder='메모를 입력해주세요.' onChange={e => handleChange(e, "dto.memo", setParams, params)} /> */}
                            </div>
                        </div>

                        <div className="input-group col-12">
                            <label htmlFor="">색상</label>
                            <div className='col-12'>
                                <input type="color" name="color" id="color" value={params.dto.color} onChange={e => handleChange(e, "dto.color", setParams, params)} />
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        {params.dto.id &&
                            <Button variant="danger" onClick={deleteSchedule}>
                                삭제
                            </Button>
                        }
                        <Button variant="primary" onClick={saveSchedule}>
                            저장
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        ) : (
            <Loading isTripMenu={true} />
        )}
    </>
);

export default ModalV;
