import { Search } from "./search";
import { Upload } from "./upload";

export class MainDTO {

    reserveContents: Array<MainReserveContentDTO> = new Array<MainReserveContentDTO>();

}

export class MainReserveContentDTO {

    sort: number;

    thumb: string;

    img: Upload;

    link: string;

    intro: string;

    btn: string;

}
