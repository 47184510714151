import React from 'react';
import Modal from "components/_common/modal/Modal"
import BigCalendar from "components/_common/BigCalendar"

const MainV = ({ t, setParams, params, mounted, submit, addReserveContent, removeReserveContent, onChangeReserveContentSort, onReserveContentFileChange, handleChange, goPage }) => (

    <>
        {mounted && (
            <>
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header collapsable">
                            <h3 className="card-title">예약 컨텐츠</h3>
                            <div className="card-tools">
                                <button
                                    type="button"
                                    className="btn-sm btn-primary float-right"
                                    onClick={() => addReserveContent()}
                                >
                                    컨텐츠추가
                                </button>
                            </div>
                        </div>

                        <div className="card-body">
                            {params.item && params.item.reserveContents && params.item.reserveContents.length > 0 && params.item.reserveContents.map((content, index) => (
                                <div className="row" key={index}>
                                    <div className="col-1">
                                        <label>노출순서</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={content.sort}
                                            onChange={(e) => {
                                                onChangeReserveContentSort(e, index);
                                            }}
                                            placeholder="노출순서"
                                        />
                                    </div>

                                    <div className="col-3">
                                        <div className="form-group">
                                            <label>노출 이미지</label>
                                            <div className="input-group">
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) =>
                                                        onReserveContentFileChange(e.target.files, index)
                                                    }
                                                />
                                                {content.img && content.img._preview && (
                                                    <div className="attachment-block clearfix">
                                                        <img
                                                            className="img col-lg-3 col-md-4 col-sm-6 col-xs-6"
                                                            src={content.img._preview}
                                                            alt="preview"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-2">
                                        <div className="form-group">
                                            <label>링크</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={content.link}
                                                onChange={e => handleChange(e, `item.reserveContents.${index}.link`, setParams, params)}
                                                placeholder="링크"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-2">
                                        <div className="form-group">
                                            <label>소개 문구</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={content.intro}
                                                onChange={e => handleChange(e, `item.reserveContents.${index}.intro`, setParams, params)}
                                                placeholder="링크"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-2">
                                        <div className="form-group">
                                            <label>예약 문구</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={content.btn}
                                                onChange={e => handleChange(e, `item.reserveContents.${index}.btn`, setParams, params)}
                                                placeholder="링크"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-1">
                                        <label>&nbsp;</label>
                                        <div className="input-group">
                                            <button
                                                type="button"
                                                className="btn-sm btn-danger float-left"
                                                onClick={() => removeReserveContent(index)}
                                            >
                                                <i className="far fa-remove"></i> 삭제
                                            </button>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="card-footer">
                    <button type="button" onClick={submit} className="btn btn-primary float-right">Save</button>
                </div>

            </>
        )}

    </>

);

export default MainV;