import pageStateService from 'core/PageState.service';
import { withRouter } from 'core/withRouter';
import { AdminDTO, RoleDTO } from 'models/admin';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import adminService from 'services/admin.service';
import pageUtil from "utils/Page.util";
import AdminUpdateV from "./Admin.update.v";
import authService from 'services/auth.service';

class Params {
    error: string = null;
    isLoaded: boolean = false;

    roles = RoleDTO.roles();

    admin: AdminDTO = new AdminDTO();

};

function AdminUpdate(props: any) {

    const navigate = useNavigate();
    const location = useLocation();
    const [params, setParams] = useState(new Params());
    const { state } = location;
    const username = state?.username;

    const isUpdate: boolean = state?.isUpdate;
    const isAdmin = useRef(authService.getToken().roles.includes("admin"));

    // const mounted = useRef(false);

    //onMount
    useEffect(() => {

        console.log("onMount");

        if (isAdmin.current) {
            params.roles = params.roles.filter(x => x.key == "admin")
        } else {
            params.roles = params.roles.filter(x => x.key != "master")
        }

        getData();

        return () => {
        }

    }, []);

    async function getData() {

        if (username) {

            try {
                const res = await adminService.get(username);
                const curParams = pageUtil.deepCopy(params);
                console.log("admin", res);

                const adminRes = res;
                for (const role of curParams.roles) {
                    role._checked = adminRes.roles.filter(p => p == role.key).length > 0;
                }

                let admin = Object.assign(new AdminDTO(), adminRes);
                // admin.account = Object.assign(new AccountDTO(), admin.account);
                curParams.admin = admin;

                setParams((prev) => ({
                    ...prev,
                    error: null,
                    isLoaded: true,
                    admin: curParams.admin,
                    roles: curParams.roles
                }));

            } catch (e) {

                console.log("exception ", e);
                setParams((prev) => ({
                    ...prev,
                    isLoaded: true,
                    error: e
                }));

                pageUtil.handleApiError(e);
            }


        } else {

            let admin = new AdminDTO();
            setParams((params) => ({
                ...params,
                isLoaded: true,
                admin: admin
            }));
        }

    }

    async function remove() {

        if (confirm("삭제하시겠습니까?")) {

            await adminService.remove(username).then((res) => {

                console.log("res", res);
                alert("완료되었습니다.");

                pageStateService.clearPageState();
                navigate("/admin", { replace: true });


            }).catch(e => {

                pageUtil.handleApiError(e);

            })
        }
    }

    async function submit() {

        let dto = pageUtil.deepCopy(params.admin);
        dto.roles = params.roles.filter(p => p._checked).map(role => role.key);
        dto.updateId = authService.getUserId();

        if (params.admin.roles.includes('master')) {
            dto.roles.push('master');
        }

        dto.updatedId = authService.getUserId();
        if (!isUpdate) dto.adminId = authService.getUserId();

        console.log("submit", params.admin, dto, isUpdate);

        await adminService.save(dto, isUpdate ? "Y" : "N").then((res) => {

            console.log("res", res);
            alert("완료되었습니다.");

            pageStateService.clearPageState();
            navigate("/admin", { replace: true });


        }).catch(e => {

            pageUtil.handleApiError(e);

        })

    }

    function handleRoleChange(e): void {
        console.log("handleRoleChange", e);

        let roles = pageUtil.deepCopy(params.roles);

        let key = e.target.name.split("_")[1];
        if (params.admin.roles.includes('master')) {
            return;
        }

        if (roles.length > 1) {
            roles.find(p => p.key != key)._checked = false;
        }
        roles.find(p => p.key == key)._checked = e.target.checked;

        setParams((prev) => ({ ...prev, roles: roles }))

    }


    const args = {
        params: params,
        setParams: setParams,
        handleChange: pageUtil.handleChange,
        handleRoleChange: handleRoleChange,
        isUpdate: isUpdate,
        submit: submit,
        remove: remove,
        // handleOrganization: handleOrganization,
    }


    // return <VAC data={props} />;

    return <AdminUpdateV {...args} />;

}


export default withRouter(AdminUpdate, [adminService]);