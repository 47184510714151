import { Search } from "./search";

export class FaqDTO {
    id: number;

    title: string;

    content: string = "";

    category: string = "all";

    sort: number = 0;

    displayYN: string = "Y";

    regdate: Date;

}

export class FaqSearchDTO extends Search {

}