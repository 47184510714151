import pageStateService from 'core/PageState.service';
import { withRouter } from 'core/withRouter';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import pageUtil from "utils/Page.util";
import BoardUpdateV from "./Board.update.v";
import authService from 'services/auth.service';
import { BoardDTO } from 'models/board';
import boardService from 'services/board.service';

class Params {

    error: string = null;
    isLoaded: boolean = false;

    item: BoardDTO = new BoardDTO();

};

function BoardUpdate(props: any) {

    const navigate = useNavigate();
    const location = useLocation();
    const [params, setParams] = useState(new Params());
    const { state } = location;
    const id = state?.id;

    const isUpdate: boolean = state?.isUpdate;

    // const mounted = useRef(false);

    //onMount
    useEffect(() => {

        console.log("onMount");

        getData();

        return () => {
        }

    }, []);

    async function getData() {

        if (id) {

            try {
                const res = await boardService.get(id);
                const curParams = pageUtil.deepCopy(params);

                console.log("item", res);

                const item = Object.assign(new BoardDTO(), res);
                if (item.thumb && item.thumb.name) item.thumb._preview = item.thumb.name;

                curParams.item = item;

                setParams((prev) => ({
                    ...prev,
                    error: null,
                    isLoaded: true,
                    item: curParams.item
                }));

            } catch (e) {

                console.log("exception ", e);
                setParams((prev) => ({
                    ...prev,
                    isLoaded: true,
                    error: e
                }));

                pageUtil.handleApiError(e);
            }


        } else {

            const item = new BoardDTO();
            setParams((params) => ({
                ...params,
                isLoaded: true,
                item: item
            }));
        }

    }

    async function remove() {

        if (confirm("삭제하시겠습니까?")) {

            await boardService.remove(id).then((res) => {

                alert("완료되었습니다.");

                pageStateService.clearPageState();
                navigate("/board", { replace: true });


            }).catch(e => {

                pageUtil.handleApiError(e);

            })
        }
    }

    async function submit() {

        let dto = pageUtil.deepCopy(params.item);

        window["oEditors"].getById["content"].exec("UPDATE_CONTENTS_FIELD", []);
        dto.content = document.querySelector("#content")["value"];

        if (!isUpdate) dto.adminId = authService.getUserId();
        dto.updateId = authService.getUserId();

        console.log("submit", params.item, dto, isUpdate);

        if (!dto.thumb.name || !dto.thumb._preview) {
            alert("썸네일 이미지를 등록해주세요.");
            return;
        }

        if (dto.alwaysVisibleYN == "Y") {
            dto.displayBeginDt = null;
            dto.displayEndDt = null;
        }

        await boardService.save(dto).then((res) => {

            console.log("res", res);
            alert("완료되었습니다.");

            pageStateService.clearPageState();
            navigate("/board", { replace: true });


        }).catch(e => {

            pageUtil.handleApiError(e);

        })

    }

    function onFileChange(e, obj: any): void {

        const files = e.target.files;

        if (files && files.length > 0) {
            // For Preview
            const file = files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {

                const item = pageUtil.deepCopy(params.item);
                item.thumb._preview = reader.result;
                item.thumb.name = file.name;
                item.thumb.size = file.size;
                item.thumb.value = reader.result;

                setParams((prev) => ({
                    ...prev,
                    item: item
                }));


            };
        }

    }

    const args = {
        params: params,
        setParams: setParams,
        handleChange: pageUtil.handleChange,
        onFileChange: onFileChange,
        isUpdate: isUpdate,
        submit: submit,
        remove: remove,
        // handleOrganization: handleOrganization,
    }


    // return <VAC data={props} />;

    return <BoardUpdateV {...args} />;

}


export default withRouter(BoardUpdate, [boardService]);