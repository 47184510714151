
export class ScheduleDTO {

    id: number;

    title: string;

    memo: string = '';

    isAllday: boolean = false;

    startDt: Date;

    endDt: Date;

    color: string;

    regdate: Date;

}