import { withRouter } from 'core/withRouter';
import React, { useEffect, useRef, useState } from 'react';
import PageUtil from 'utils/Page.util';
import ModalV from './Modal.v';
import scheduleService from 'services/schedule.service';
import moment from 'moment';
import { ScheduleDTO } from 'models/schedule';

class Params {

    error: any = null;

    isLoaded: boolean = false;

    dto: ScheduleDTO = new ScheduleDTO();

}

function Modal(props: any) {
    const [params, setParams] = useState(new Params());
    const closeModal: Function = props.closeModal;
    console.log('params', params);

    const stateRef = useRef(new Params());

    /* ***************************************************************************************** */
    /* ************************************* STATE ********************************************* */
    /* ***************************************************************************************** */
    useEffect(() => {
        console.log('change props');

        const curParams = PageUtil.deepCopy(params) as Params;
        curParams.isLoaded = true;
        curParams.dto = props.args;

        setParams(curParams);
    }, [props]);

    //onMount
    useEffect(() => {

    }, []);

    // 패키지 선택은 하나만 할 수 있음.
    function handleCheckbox(packageObj: any) {
        // console.log('packageObj', packageObj);
        const curParams = PageUtil.deepCopy(params) as Params;

        setParams(curParams);
    }

    let isSaved = false;
    async function saveSchedule() {

        let copyDto = PageUtil.deepCopy(params.dto);

        if (!copyDto.startDt) {
            alert("시작일시를 선택해주세요.");
            return;
        }

        if (!copyDto.endDt) {
            alert("종료일시를 선택해주세요.");
            return;
        }

        if (!copyDto.title) {
            alert("제목을 선택해주세요.");
            return;
        }

        if (isSaved) return;

        isSaved = true;

        try {
            console.log("copyDto", copyDto);
            const res = await scheduleService.save(copyDto);

            if (res.errMsg) {
                alert(res.errMsg);
                return;
            }

            alert('저장 되었습니다.');
            isSaved = false;
            closeModal(copyDto);
        } catch (error) {
            setParams((prev) => ({
                ...prev,
                error: error,
            }));

            PageUtil.handleApiError(error);
        }

    }

    let isDeleted = false;
    async function deleteSchedule() {

        let copyDto = PageUtil.deepCopy(params.dto);

        if (!confirm("정말 삭제하시겠습니까?")) return;

        if (isDeleted) return;

        isDeleted = true;

        try {
            const res = await scheduleService.delete(params.dto.id);

            if (res.errMsg) {
                alert(res.errMsg);
                return;
            }

            alert('삭제 되었습니다.');
            isDeleted = false;
            closeModal(copyDto);
        } catch (error) {
            setParams((prev) => ({
                ...prev,
                error: error,
            }));

            PageUtil.handleApiError(error);
        }

    }

    const args = {
        params: params,
        setParams: setParams,
        handleChange: PageUtil.handleChange,
        closeModal: closeModal,
        handleCheckbox: handleCheckbox,
        saveSchedule: saveSchedule,
        deleteSchedule: deleteSchedule,
    };

    return <ModalV {...args} />;
}
export default withRouter(Modal);
