import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from "core/route.protected";
import Loading from "Loading";
import BoardList from './Board.list';
import BoardUpdate from './Board.update';

function Board() {
  return (
    <>

      <Routes>
        <Route path='/' element={
          <ProtectedRoute>
            <React.Suspense fallback={<Loading />}><BoardList /></React.Suspense>
          </ProtectedRoute>
        }></Route>

        <Route path='/add' element={
          <ProtectedRoute>
            <React.Suspense fallback={<Loading />}><BoardUpdate /></React.Suspense>
          </ProtectedRoute>
        }></Route>

        <Route path='/update' element={
          <ProtectedRoute>
            <React.Suspense fallback={<Loading />}><BoardUpdate /></React.Suspense>
          </ProtectedRoute>
        }></Route>

      </Routes>
    </>

  );
};

export default Board;
