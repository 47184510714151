import axios from 'axios';
import authService from 'services/auth.service'

axios.interceptors.request.use(
  (config) => {
    var token = localStorage.getItem('admin-token')
    // if (token && config.url != "/oauth/token" && config.url.indexOf(process.env.REACT_APP_S3_DOWNLOAD_BUCKET) < 0) {
    if (token && config.url != "/oauth/token") {
      let tokenObj = JSON.parse(token);
      config.headers['Authorization'] = `Bearer ${tokenObj.access_token}`;
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    // 새로고침될때 response.data 가 않오고 response 로 오는 경우
    return response.data ? response.data : response;
  },
  async (error) => {
    const originalRequest = error.config;

    /**
     * 일반적인 WAS환경에서는 토큰 만료시 api 를 호출하면 401 오류가 발생시키고 
     * if (error.response && error.response.status === 401 && !originalRequest._retry) { 로직으로 
     * refreshToken 을 요청하는데,
     * AWS api gateway 의 authorizer 는 403 (forbidden) 을 리턴하기때문에 
     * 토큰만료시 api 가 403을 리턴을 하는것을 고려하여 로직 처리
     * 
     */

    //console.log("interceptors.response.err" , error , error.code);

    if (error.code == "ERR_CANCELED") {    // axios cancel
      //console.log("ERR_CANCELED");
      return Promise.reject(error);
    }


    if (error.response && (error.response.status === 403) && !originalRequest._retry) {
      originalRequest._retry = true;

      try {

        let data = await authService.refreshToken();
        let token = data;

        originalRequest.headers.Authorization = `Bearer ${token.access_token}`;
        authService.saveToken(token);
        return axios(originalRequest);

      } catch (error) {
        console.log("use exception", error);
        authService.signOut();
      }

    }

    return Promise.reject(error);
  }
);



export default axios;