import React from 'react';

const AdminUpdateV = ({ t, params, setParams, handleChange, handleRoleChange, isUpdate, submit, remove }) => (
    <>

        <div className="content-header">
            <h1>
                <font>ADMIN</font>
            </h1>
        </div>
        <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Registration</h3>

                    <div className="card-tools">
                    </div>

                </div>
                {params.isLoaded && params.error == null && (
                    <>
                        <div className="g_update p-0 mt_30">
                            <table className="g_table table_type1">
                                <colgroup>
                                    <col width="220" />
                                    <col />
                                </colgroup>
                                <tbody>

                                    <tr>
                                        <th><label>username</label></th>
                                        {isUpdate && (
                                            <td><div className="inner"><input className="form-control input-sm" type="text" readOnly value={params.admin.username} /></div></td>
                                        )}
                                        {!isUpdate && (
                                            <td><div className="inner"><input className="form-control input-sm" type="text" onChange={e => handleChange(e, "admin.username", setParams, params)} /></div></td>
                                        )}
                                    </tr>

                                    <tr>
                                        <th><label>name</label></th>
                                        {isUpdate && (
                                            <td><div className="inner"><input className="form-control input-sm" type="text" onChange={e => handleChange(e, "admin.name", setParams, params)} value={params.admin.name} /></div></td>
                                        )}
                                        {!isUpdate && (
                                            <td><div className="inner"><input className="form-control input-sm" type="text" onChange={e => handleChange(e, "admin.name", setParams, params)} /></div></td>
                                        )}
                                    </tr>

                                    <tr>
                                        <th><label>password</label></th>
                                        {isUpdate && (
                                            <td><div className="inner"><input className="form-control input-sm" type="password" onChange={e => handleChange(e, "admin.password", setParams, params)} value={params.admin.password} placeholder="password" /></div></td>
                                        )}
                                        {!isUpdate && (
                                            <td><div className="inner"><input className="form-control input-sm" type="password" onChange={e => handleChange(e, "admin.password", setParams, params)} /></div></td>
                                        )}
                                    </tr>

                                    <tr>
                                        <th><label>phone</label></th>
                                        {isUpdate && (
                                            <td><div className="inner"><input className="form-control input-sm" type="text" onChange={e => handleChange(e, "admin.phone", setParams, params)} value={params.admin.phone} /></div></td>
                                        )}
                                        {!isUpdate && (
                                            <td><div className="inner"><input className="form-control input-sm" type="text" onChange={e => handleChange(e, "admin.phone", setParams, params)} /></div></td>
                                        )}
                                    </tr>

                                    <tr>
                                        <th><label>email</label></th>
                                        {isUpdate && (
                                            <td><div className="inner"><input className="form-control input-sm" type="text" onChange={e => handleChange(e, "admin.email", setParams, params)} value={params.admin.email} /></div></td>
                                        )}
                                        {!isUpdate && (
                                            <td><div className="inner"><input className="form-control input-sm" type="text" onChange={e => handleChange(e, "admin.email", setParams, params)} /></div></td>
                                        )}
                                    </tr>

                                    <tr>
                                        <th><label>role</label><br></br></th>
                                        <td>
                                            <div className="inner">
                                                {params.roles.map((item) => (
                                                    <div className="form-check checkbox-lg form-check-inline" key={item.key}>
                                                        <input className="form-check-input" type="checkbox" onChange={handleRoleChange} checked={item._checked} name={'role_' + item.key} id={'role_' + item.key} value={item.key} disabled={params.admin.roles?.includes('master')} />
                                                        <label className="form-check-label" htmlFor={'role_' + item.key}>{item.name}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </td>
                                    </tr>
                                   
                                </tbody>
                            </table>
                        </div>

                        <div className="card-footer">
                            <a className="btn btn-success float-left" onClick={() => { history.back() }}><i className='fas fa-long-arrow-left mr_10'></i>Back</a>

                            <button type="button" onClick={submit} className="btn btn-primary float-right">Save</button>
                            {isUpdate && (
                                <button type="button" onClick={remove} className="btn btn-danger float-right mr_10">Remove</button>
                            )}
                        </div>
                    </>
                )
                }

            </div>

        </div>

    </>
);

export default AdminUpdateV;