import React from 'react';
import { Calendar } from 'react-big-calendar';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
const DragAndDropCalendar = withDragAndDrop(Calendar);
import moment from 'moment';
import { momentLocalizer } from 'react-big-calendar';
import "./style.css";

const localizer = momentLocalizer(moment);

const BigCalendar = ({
  height,
  items,
  moveEvent,
  resizeEvent,
  newEvent,
  handleSelectEvent,
  rangeChangeEvent
}) => {
  return (
    <>
      <div style={{ height: height }}>
        <DragAndDropCalendar
          localizer={localizer}
          events={items}
          onEventDrop={moveEvent}
          onEventResize={resizeEvent}
          draggableAccessor="isDraggable"
          onSelectSlot={newEvent}
          longPressThreshold={500}
          onSelectEvent={handleSelectEvent}
          resizable
          selectable
          onRangeChange={rangeChangeEvent}
          views={['month', 'week', 'day']}
          style={{ height: "100vh" }}
          eventPropGetter={eventStyleGetter}
        // style={{ height: 900 }}
        // components={{ toolbar: Toolbar }}
        />
      </div>

    </>
  );
};

// function moveEvent(e): void {
//   console.log("moveEvent e", e);
// }

// function resizeEvent(e): void {
//   console.log("resizeEvent e", e);
// }

// function newEvent(e): void {
//   console.log("newEvent e", e);
// }

// function handleSelectEvent(e): void {
//   console.log("handleSelectEvent e", e);
// }

// function rangeChangeEvent(e): void {
//   console.log("rangeChangeEvent", e);
// }

// function showModal() {
//   console.log("showModal");
// }

// function closeModal() {
//   console.log("closeModal");
// }

const eventStyleGetter = (event) => {
  const backgroundColor = event.color || '#3174ad'; // 기본 색상 설정
  const style = {
    backgroundColor,
    borderRadius: '5px',
    opacity: 0.8,
    color: 'white',
    border: '0px',
    display: 'block'
  };
  return {
    style: style
  };
};

export default BigCalendar;