import pageStateService from 'core/PageState.service';
import { withRouter } from 'core/withRouter';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import pageUtil from "utils/Page.util";
import FaqUpdateV from "./Faq.update.v";
import authService from 'services/auth.service';
import faqService from 'services/faq.service';
import { FaqDTO } from 'models/faq';

class Params {

    error: string = null;
    isLoaded: boolean = false;

    item: FaqDTO = new FaqDTO();

};

function FaqUpdate(props: any) {

    const navigate = useNavigate();
    const location = useLocation();
    const [params, setParams] = useState(new Params());
    const { state } = location;
    const id = state?.id;

    const isUpdate: boolean = state?.isUpdate;

    // const mounted = useRef(false);

    //onMount
    useEffect(() => {

        console.log("onMount");

        getData();

        return () => {
        }

    }, []);

    async function getData() {

        console.log("id", id);

        if (id) {

            try {
                const res = await faqService.get(id);
                const curParams = pageUtil.deepCopy(params);

                console.log("item", res);

                const item = Object.assign(new FaqDTO(), res);
                curParams.item = item;

                setParams((prev) => ({
                    ...prev,
                    error: null,
                    isLoaded: true,
                    item: curParams.item
                }));

            } catch (e) {

                console.log("exception ", e);
                setParams((prev) => ({
                    ...prev,
                    isLoaded: true,
                    error: e
                }));

                pageUtil.handleApiError(e);
            }


        } else {

            const item = new FaqDTO();
            setParams((params) => ({
                ...params,
                isLoaded: true,
                item: item
            }));
        }

    }

    async function remove() {

        if (confirm("삭제하시겠습니까?")) {

            await faqService.remove(id).then((res) => {

                alert("완료되었습니다.");

                pageStateService.clearPageState();
                navigate("/faq", { replace: true });


            }).catch(e => {

                pageUtil.handleApiError(e);

            })
        }
    }

    async function submit() {

        let dto = pageUtil.deepCopy(params.item);

        console.log("submit", params.item, dto, isUpdate);

        await faqService.save(dto).then((res) => {

            console.log("res", res);
            alert("완료되었습니다.");

            pageStateService.clearPageState();
            navigate("/faq", { replace: true });


        }).catch(e => {

            pageUtil.handleApiError(e);

        })

    }

    const args = {
        params: params,
        setParams: setParams,
        handleChange: pageUtil.handleChange,
        isUpdate: isUpdate,
        submit: submit,
        remove: remove,
        // handleOrganization: handleOrganization,
    }


    // return <VAC data={props} />;

    return <FaqUpdateV {...args} />;

}


export default withRouter(FaqUpdate, [faqService]);