import DashboardV from './Dashboard.v';
import { withRouter } from 'core/withRouter';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import PageUtil from 'utils/Page.util';
import moment from 'moment';
import scheduleService from 'services/schedule.service';
import { ModalDTO } from 'models/common';
import stockService from 'services/stock.service';

class Params {
    modal: ModalDTO = new ModalDTO();
    items = [];
    stock = [];
};

function Dashboard(props: any) {

    const navigate = useNavigate();
    const [params, setParams] = useState(new Params());
    const [mounted, setMounted] = useState(false);

    //onMount
    useEffect(() => {

        setMounted(() => true);

        return () => {
            console.log("componentWillUnmount");
        };

    }, []);

    async function getData(startYmd?: string, endYmd?: string): Promise<any> {

        let start = startYmd;
        let end = endYmd;

        if (!start) start = moment().add(-1, 'month').format("YYYYMMDD");
        if (!end) end = moment().add(1, 'month').format("YYYYMMDD");

        await scheduleService.get(start, end).then(p => {

            console.log("getData", p);

            let items = [];

            p.forEach(data => {
                let event = {
                    id: data.id,
                    title: data.title,
                    memo: data.memo,
                    start: moment(data.startDt).toDate(),
                    end: moment(data.endDt).toDate(),
                    regdate: moment(data.regdate).toDate(),
                    color: data.color ? data.color : ""
                }
                items.push(event);
            });

            setParams((params) => ({
                ...params,
                items: items,
                isLoaded: true,
            }));

        }).catch(e => {

            setParams((params) => ({
                ...params,
                isLoaded: true,
                error: e
            }));

            PageUtil.handleApiError(e);

        });

    }

    async function getStockSummary(): Promise<any> {

        await stockService.getSummary().then(p => {

            console.log("getStockSummary", p);

            setParams((params) => ({
                ...params,
                stock: p,
                isLoaded: true,
            }));

        }).catch(e => {

            setParams((params) => ({
                ...params,
                isLoaded: true,
                error: e
            }));

            PageUtil.handleApiError(e);

        });

    }

    function goPage(path: string) {
        navigate(path);
    }

    const args = {
        params: params,
        setParams: setParams,
        mounted: mounted,
        goPage: goPage,
        handleChange: PageUtil.handleChange,
    }

    return (
        <DashboardV {...args}></DashboardV>
    );

}

export default withRouter(Dashboard);