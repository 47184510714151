import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from "core/route.protected";
import Loading from "Loading";
import FaqList from './Faq.list';
import FaqUpdate from './Faq.update';

function Faq() {
  return (
    <>

      <Routes>
        <Route path='/' element={
          <ProtectedRoute>
            <React.Suspense fallback={<Loading />}><FaqList /></React.Suspense>
          </ProtectedRoute>
        }></Route>

        <Route path='/add' element={
          <ProtectedRoute>
            <React.Suspense fallback={<Loading />}><FaqUpdate /></React.Suspense>
          </ProtectedRoute>
        }></Route>

        <Route path='/update' element={
          <ProtectedRoute>
            <React.Suspense fallback={<Loading />}><FaqUpdate /></React.Suspense>
          </ProtectedRoute>
        }></Route>

      </Routes>
    </>

  );
};

export default Faq;
