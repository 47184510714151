import { withRouter } from 'core/withRouter';
import React, { useEffect, useRef, useState } from 'react';
import BoardListV from "./Board.list.v"
import pageUtil from "utils/Page.util";
import { BoardDTO, BoardSearchDTO } from 'models/board';
import boardService from 'services/board.service';

class Params {
    error: string = null;
    isLoaded: boolean = false;
    items: Array<BoardDTO>;
    total: number = 0;

    search = new BoardSearchDTO();

};

function BoardList(props: any) {

    const [params, setParams] = useState(new Params());
    const [search, setSearch] = useState(false);
    // const stateRef = useRef(new Params());
    const mounted = useRef(false);

    /****************************************************************************************** */
    /************************************** STATE ********************************************* */
    /****************************************************************************************** */

    // onUpdated
    // 검색조건이 변경된 경우
    useEffect(() => {
        if (!mounted.current || !search) {
            return;
        }

        if (search) {
            console.log("filter changed");
            setSearch(false);
            getData();
        }

    }, [search]);

    // 상태 유지
    // useEffect(() => {
    //     stateRef.current = params as any;
    // }, [params]);

    // onMount
    useEffect(() => {

        console.log("onMount");
        console.log("LOAD FROM API");
        mounted.current = true;

        console.log("mounted");
        getData();

    }, []);


    /************************************************************************************************************************ */
    /***************************************************** BASIC ************************************************************ */
    /************************************************************************************************************************ */


    function pageChanged(page: number): void {
        let search = pageUtil.deepCopy(params.search);
        search.page = page;
        setParams((params) => ({
            ...params,
            search: search
        }));

        setSearch(true);
    }

    async function onChangeRowUnit(e: any) {

        console.log("e.target.value", e.target.value);

        const search = pageUtil.deepCopy(params.search);

        search.rows = e.target.value;
        search.page = 1;

        setParams((prev) => ({
            ...prev,
            search: search
        }));

        setSearch(true);

    }

    async function getData(): Promise<any> {

        console.log("params.search", params.search);

        await boardService.getList(params.search).then(p => {

            console.log("getList", p);

            setParams((params) => ({
                ...params,
                error: null,
                isLoaded: true,
                items: p.content,
                total: p.totalElements,
            }));

        }).catch(e => {

            setParams((params) => ({
                ...params,
                isLoaded: true,
                error: e
            }));

            pageUtil.handleApiError(e);

        });

    }



    const args = {
        params: params,
        pageChanged: pageChanged,
        onChangeRowUnit: onChangeRowUnit
    }

    return (
        <BoardListV
            {...args} />
    );



}

export default withRouter(BoardList, [boardService]);