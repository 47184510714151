import { Search } from "./search";
import { Upload } from "./upload";

export class BoardDTO {
    id: number;

    title: string;

    content: string;

    type: string = "normal";

    thumb: Upload = new Upload();

    displayBeginDt: Date;

    displayEndDt: Date;

    displayYN: string;

    alwaysVisibleYN: string;

    adminId: string;

    regdate: Date;

    updateId: string;

    updateDate: Date;
}

export class BoardSearchDTO extends Search {

}