import moment, { isDate } from 'moment';
import 'moment/locale/ko';

export const stringToDate = (ymd: string, format?: string) => {
    if (!format) format = 'YYYYMMDD';

    // ymd 가 null이면 null을 리턴 -> datePicker에서 selected속성 에러 방지
    if (!ymd) return null;

    return moment(ymd, format).toDate();
};

export const dateFormat = (date: Date, format?: string) => {
    if (!format) {
        format = "YYYY.MM.DD";
    }

    if (!date || isNaN(date.getTime())) return "";

    return moment(date).format(format);
}

export const ymdhmsToDate = (ymdhmsString: string) => {
    if (!/^\d{12}$/.test(ymdhmsString)) {
        throw new Error("Invalid YMDHMS format. Expected 'YYYYMMDDHHmm'.");
    }

    const year = parseInt(ymdhmsString.slice(0, 4), 10);  // YYYY
    const month = parseInt(ymdhmsString.slice(4, 6), 10) - 1; // MM (0-based)
    const day = parseInt(ymdhmsString.slice(6, 8), 10);    // DD
    const hour = parseInt(ymdhmsString.slice(8, 10), 10);  // HH
    const minute = parseInt(ymdhmsString.slice(10, 12), 10); // mm

    return new Date(year, month, day, hour, minute, 0);
}


export function toHoursAndMinutes(totalSeconds, format?: string) {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (!format) {
        format = "Hh Mm";
    }
    return format.replace("H", hours.toString()).replace("M", minutes.toString());
}