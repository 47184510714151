import React from 'react';
import { RingLoader } from 'react-spinners';
import './style.css';

/*
    props.isTripMenu : 투어 메뉴 접근시 true, 그 외 메뉴 접근 시 false (css 분기 처리를 위함)
*/
function Loading(props: any) {
    console.log('props', props);

    return (
        <>
            <div className={`${props.isTripMenu ? 'loading-container-in-trip' : 'loading-container'}`}>
                <RingLoader
                    color={'#3c5c23'}
                    loading={true}
                    size={70}
                    // speedMultiplier={0.4}
                />
            </div>
        </>
    );
}

export default Loading;
