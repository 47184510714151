import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";

export const withRouter = (Component: any, services: any[] = null) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();

    useEffect(() => {

      return () => {
        console.log("withRouter disapear");
        if (services && services.length > 0) {
          for (let service of services) {
            if (service.cancel != undefined) {
              service.cancel();
            }
          }
        }

      };

    }, []);



    return (
      <Component
        navigate={navigate}
        {...props}
        params={useParams()}
      />
    );
  };

  return Wrapper;
};