import moment from "moment";
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router";
import authService from "services/auth.service";
import pageUtil from "utils/Page.util";

class Params {

  isMember: boolean = false;
  items: Array<any> = [];
};
function NavBar() {
  const navigate = useNavigate();
  const [params, setParams] = useState(new Params());
  const [mounted, setMounted] = useState(false);

  //onMount
  useEffect(() => {

    console.log("NavBar onMount");

    setMounted(true);

  }, []);

  function openMember() {
    setParams((prev) => ({
      ...prev,
      isMember: !prev.isMember,
      isLang: false,
      isNoti: false,
    }));
  }

  async function signOut() {

    await authService.signOut(true);
    navigate("/login?r=" + location.pathname);

  }


  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      {mounted && (
        <>
          <ul className="navbar-nav">
            <li className="nav-item relative">
              <a className="nav-link ic_expend" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
            </li>
          </ul>

          <ul className="navbar-nav util">

            <li>
              <span className="ic_text member" onClick={openMember}>{authService.getUserId()}</span>
              {params.isMember && (
                <div className="sub_layer">
                  <ul>
                    <li><button className="g_button" onClick={signOut}>로그아웃</button></li>
                  </ul>
                </div>
              )}
            </li>
          </ul>
        </>
      )}
    </nav >
  );

}

export default NavBar;