import http from 'core/http.service';
import BaseService from "services/BaseService";

class StockService extends BaseService {

    public getList(search): Promise<any> {
        return super.request(http.get("/stock", search));
    }

    public remove(id: number): Promise<void> {
        return super.request(http.delete("/stock/" + id));
    }

    public save(list: Array<any>): Promise<void> {
        return super.request(http.post("/stock", list));
    }

    public getSummary(): Promise<any> {
        return super.request(http.get("/stock/summary"));
    }

}

let stockService;
export default stockService = new StockService();