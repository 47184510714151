import React from 'react';
import Editor from '../_common/editor/editor';

const BoardUpdateV = ({ t, params, setParams, handleChange, handleChangeCategory, isUpdate, submit, remove }) => (
    <>

        <div className="content-header">
            <h1>
                <font>게시글</font>
            </h1>
        </div>
        <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">{isUpdate ? "수정" : "등록"}</h3>

                    <div className="card-tools">
                    </div>

                </div>
                {params.isLoaded && params.error == null && (
                    <>
                        <div className="g_update p-0 mt_30">
                            <table className="g_table table_type1">
                                <colgroup>
                                    <col width="220" />
                                    <col />
                                </colgroup>
                                <tbody>

                                    <tr>
                                        <th><label>제목</label></th>
                                        <td><div className="inner"><input className="form-control input-sm" type="text" onChange={e => handleChange(e, "item.title", setParams, params)} value={params.item.title} /></div></td>
                                    </tr>

                                    <tr>
                                        <th><label>카테고리</label></th>
                                        <td>
                                            <div className="inner">
                                                <span className="g_select">
                                                    <select className="form-control" onChange={e => handleChange(e, "item.category", setParams, params)} value={params.item.category}>
                                                        <option value="all" >공통</option>
                                                        <option value="hapjeong_darak" >합정다락</option>
                                                        <option value="darakparty" >다락파티</option>
                                                        <option value="darak_chaegjang" >다락책장</option>
                                                    </select>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th><label>내용</label></th>
                                        <td>
                                            <div>
                                                <textarea name="content" id="content" rows={15} style={{ width: "100%", height: "100%", resize: "none" }} onChange={e => handleChange(e, "item.content", setParams, params)} value={params.item.content}></textarea>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th><label>노출순서</label></th>
                                        <td><div className="inner"><input className="form-control input-sm" type="number" onChange={e => handleChange(e, "item.sort", setParams, params)} value={params.item.sort} /></div></td>
                                    </tr>

                                    <tr>

                                        <th><label>노출여부</label></th>
                                        <td>
                                            <div className="icheck-primary d-inline mr-3">
                                                <input
                                                    type="radio"
                                                    id="radioPrimary1"
                                                    name="display"
                                                    checked={params.item.displayYN === 'Y'}
                                                    value={"Y"}
                                                    onChange={e => handleChange(e, "item.displayYN", setParams, params)}
                                                />
                                                <label htmlFor="radioPrimary1">노출</label>
                                                <input
                                                    type="radio"
                                                    id="radioPrimary2"
                                                    name="display"
                                                    checked={params.item.displayYN === 'N'}
                                                    value={"N"}
                                                    onChange={e => handleChange(e, "item.displayYN", setParams, params)}
                                                />
                                                <label htmlFor="radioPrimary2">미노출</label>
                                            </div>
                                        </td>

                                    </tr>

                                    {/* <tr>
                                        <th><label>role</label><br></br></th>
                                        <td>
                                            <div className="inner">
                                                {params.roles.map((item) => (
                                                    <div className="form-check checkbox-lg form-check-inline" key={item.key}>
                                                        <input className="form-check-input" type="checkbox" onChange={handleRoleChange} checked={item._checked} name={'role_' + item.key} id={'role_' + item.key} value={item.key} disabled={params.admin.roles?.includes('master')} />
                                                        <label className="form-check-label" htmlFor={'role_' + item.key}>{item.name}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </td>
                                    </tr> */}

                                </tbody>
                            </table>
                        </div>

                        <div className="card-footer">
                            <a className="btn btn-success float-left" onClick={() => { history.back() }}><i className='fas fa-long-arrow-left mr_10'></i>Back</a>

                            <button type="button" onClick={submit} className="btn btn-primary float-right">Save</button>
                            {isUpdate && (
                                <button type="button" onClick={remove} className="btn btn-danger float-right mr_10">Remove</button>
                            )}
                        </div>
                    </>
                )
                }

            </div>

        </div>

    </>
);

export default BoardUpdateV;