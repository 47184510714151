import http from 'core/http.service';
import BaseService from "services/BaseService";
import { Page } from 'models/page';
import { FaqDTO, FaqSearchDTO } from 'models/faq';

class FaqService extends BaseService {

    public getList(search: FaqSearchDTO): Promise<Page<FaqDTO>> {
        return super.request(http.get("/faq", search));
    }

    public get(id: number): Promise<FaqDTO> {
        return super.request(http.get("/faq/" + id));
    }

    public save(dto: FaqDTO): Promise<void> {
        return super.request(http.post("/faq", dto));
    }

    public remove(id: number): Promise<void> {
        return super.request(http.delete("/faq/" + id));
    }

}

let faqService;
export default faqService = new FaqService();