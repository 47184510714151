import qs from 'qs';
import axios from 'core/axios.service';

class AuthService {

    public async signIn(username: string, password: string): Promise<any> {

        const uri = "oauth/token";

        const data = {
            'username': username,
            'password': password,
            'clientid': this.getClientId()
        };

        const headers = {
            'content-type': 'application/json',
        };

        try {

            let res = await axios.post(uri, data)

            let token = res.data;

            this.saveToken(token);
            return token;

        } catch (err) {
            console.log("err", err);
            throw err;
        }

    }

    public async signOut(forceServer?: boolean): Promise<boolean> {
        /*
        var http = axios.create({
          baseURL: (axios.defaults.baseURL as string).replace('/api' , '')
        });
        */
        if (forceServer) {
            //  await axios.delete('/admin-api/auth/revoke');
        }

        this.removeToken();

        window.location.href = "/login";

        return true;



    }

    public refreshToken(): Promise<any> {

        let token = this.getToken();

        const uri = "/oauth/token";

        const data = {
            'grant_type': 'refresh_token',
            'username': token.username,
            'refresh_token': token.refresh_token,
            'clientid': this.getClientId()
        };

        const headers = {
            'content-type': 'application/json',
        };

        return axios.post(uri, data)

    }

    public removeToken() {
        localStorage.removeItem('admin-token');
    }

    public getToken() {
        if (localStorage.getItem('admin-token')) {
            return JSON.parse(localStorage.getItem('admin-token'));
        } else {
            return "";
        }   
    }

    public saveToken(token) {
        localStorage.setItem('admin-token', JSON.stringify(token));

    }

    public getClientId(): string {
        return this.getRememberMe() ? "longadminclient" : "adminclient";
    }

    public setRememberMe(arg: boolean): void {
        localStorage.setItem("rememberMe", arg ? "Y" : "N");
    }

    public getRememberMe(): boolean {
        let arg = localStorage.getItem("rememberMe");
        return (!arg || arg == "N") ? false : true;
    }

    public getUserId(): string {
        let username: string = "";
        if (localStorage.getItem("userId")) {
            username = localStorage.getItem("userId");
        }
        return username;
    }

    public setUserId(username?: string): void {
        localStorage.setItem("userId", username);
    }

    public getSaveId(): boolean {
        let save: boolean = false;
        if (localStorage.getItem("saveId")) {
            save = true;
        } else {
            localStorage.removeItem("userId")
        }
        return save;
    }

    public setSaveId(save: boolean): void {
        if (save) {
            localStorage.setItem("saveId", "Y");
        } else {
            localStorage.removeItem("saveId");
        }

    }

}

let authService;
export default authService = new AuthService();