import React, { useEffect, useRef } from 'react';

function Editor(props) {

    useEffect(() => {

        console.log("onMount");

        setTimeout(() => {
            document.querySelector("#content").parentElement.querySelectorAll("iframe")?.forEach(p => p.remove())

            if (window["initEditor"]) {
                window["initEditor"](window["oEditors"], "content");
            }
        }, 350);

        return () => {

        };

    }, []);

    return (
        <>
            <textarea name="content" id="content" style={{ display: "none" }} value={props.value} readOnly></textarea>
        </>
    );
}

export default Editor;
