import http from 'core/http.service';
import BaseService from "services/BaseService";
import { MainDTO } from 'models/main';

class MainService extends BaseService {

    public get(): Promise<MainDTO> {
        return super.request(http.get("/main"));
    }

    public save(dto: MainDTO): Promise<void> {
        return super.request(http.post("/main", dto));
    }

}

let mainService;
export default mainService = new MainService();